import * as React from 'react';

import type { SxProps, Theme } from '@mui/material/styles';
import type { FieldError, FieldErrors } from 'react-hook-form';

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { get } from 'react-hook-form';

// @todo import { ERROR_SEPARATOR } from '~/utils/form';

interface Props<T extends FieldErrors> {
  errors?: string | T;
  fields?: string[];
  sx?: SxProps<Theme>;
}

/**
 * class to display trcp errors
 *
 */
const FormError = <T extends FieldErrors>({ fields = ['root'], errors, sx }: Props<T>) => {
  const errorsList: string[] = [];

  if (typeof errors === 'string') {
    errorsList.push(errors);
  } else if (errors) {
    fields.map((field) => {
      const error = get(errors, field) as FieldError | undefined;
      if (error?.message) {
        errorsList.push(...error.message.split('%|%'));
      }
    });
  }

  if (errorsList.length === 0) {
    return null;
  }

  const errorDisplay = errorsList.map((error) => <div key={error}>{error}</div>);

  return (
    <Collapse in sx={{ ...sx }}>
      <Alert severity="error">{errorDisplay}</Alert>
    </Collapse>
  );
};

export const FormErrorString = (props: { errors: string[] }) => {
  const errorDisplay = props.errors.map((error) => <div key={error}>{error}</div>);

  return (
    <Collapse in>
      <Alert severity="error">{errorDisplay}</Alert>
    </Collapse>
  );
};

export default FormError;
