import * as React from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { useController } from 'react-hook-form';

export type OtpFieldElementProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  helperText?: string;
};

const OtpFieldElement = <T extends FieldValues>({ control, name, helperText }: OtpFieldElementProps<T>) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const optLength = 6;

  const inputRefs = React.useRef<Array<HTMLInputElement | null>>(new Array(optLength).fill(null));

  const onChange = (value: string | ((value: string) => string)) => {
    if (typeof value === 'function') {
      field.onChange(value(field.value));
    } else {
      field.onChange(value);
    }
  };

  const focusInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput?.focus();
  };

  const selectInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput?.select();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case ' ':
        event.preventDefault();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (currentIndex < optLength - 1) {
          focusInput(currentIndex + 1);
          selectInput(currentIndex + 1);
        }
        break;
      case 'Delete':
        event.preventDefault();
        onChange((prevOtp) => {
          const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });

        break;
      case 'Backspace':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }

        onChange((prevOtp) => {
          const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
    const currentValue = event.target.value;
    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (inputRefs.current[indexToEnter]?.value && indexToEnter < currentIndex) {
        indexToEnter += 1;
      } else {
        break;
      }
    }

    onChange((prev) => {
      const otpArray = prev.split('');
      const lastValue = currentValue[currentValue.length - 1];
      if (lastValue !== undefined) {
        otpArray[indexToEnter] = lastValue;
      }
      return otpArray.join('');
    });

    if (currentValue !== '') {
      if (currentIndex < optLength - 1) {
        focusInput(currentIndex + 1);
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>, currentIndex: number) => {
    selectInput(currentIndex);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, currentIndex: number) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;

    // Check if there is text data in the clipboard
    if (clipboardData.types.includes('text/plain')) {
      let pastedText = clipboardData.getData('text/plain');
      pastedText = pastedText.substring(0, optLength).trim();
      let indexToEnter = 0;

      while (indexToEnter <= currentIndex) {
        if (inputRefs.current[indexToEnter]?.value && indexToEnter < currentIndex) {
          indexToEnter += 1;
        } else {
          break;
        }
      }

      const otpArray = field.value.split('');

      for (let i = indexToEnter; i < optLength; i += 1) {
        const lastValue = pastedText[i - indexToEnter] ?? ' ';
        otpArray[i] = lastValue;
      }

      onChange(otpArray.join(''));
    }
  };

  return (
    <>
      <Stack
        direction="row"
        divider={
          <Box component="span" sx={{ color: 'divider' }}>
            &bull;
          </Box>
        }
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        {Array.from({ length: optLength }).map((_, index) => (
          <OutlinedInput
            // biome-ignore lint/suspicious/noArrayIndexKey: we need to use index as key
            key={index}
            aria-label={`Digit ${index} of OTP`}
            inputRef={(ele) => {
              inputRefs.current[index] = ele;
            }}
            error={!!fieldState.error}
            onKeyDown={(event) => handleKeyDown(event as React.KeyboardEvent<HTMLInputElement>, index)}
            onChange={(event) => handleChange(event as React.ChangeEvent<HTMLInputElement>, index)}
            onClick={(event) => handleClick(event as React.MouseEvent<HTMLInputElement>, index)}
            onPaste={(event) => handlePaste(event as React.ClipboardEvent<HTMLInputElement>, index)}
            value={field.value[index] ?? ''}
            inputProps={{
              sx: {
                textAlign: 'center',
              },
            }}
            sx={{ width: '2.5rem', height: '2.5rem' }}
          />
        ))}
      </Stack>
      <FormHelperText error={!!fieldState.error}>{fieldState.error ? fieldState.error.message : (helperText ?? ' ')}</FormHelperText>
    </>
  );
};

export default OtpFieldElement;
