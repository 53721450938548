'use client';

import { standardSchemaResolver as schemaResolver } from '@hookform/resolvers/standard-schema';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import { useSession } from 'next-auth/react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useI18n } from '@pegase/i18n/client';
import FormError from '@pegase/ui/form/form-error';
import TextFieldElement from '@pegase/ui/form/text-field-element';

import { signInAction } from './actions';

const magicLinkSchema = z.object({
  email: z.string().email(),
});

interface Props {
  redirectTo: string;
}

const Credentials = (props: Props) => {
  const t = useI18n();
  const session = useSession();

  const { control, handleSubmit, formState } = useForm<z.infer<typeof magicLinkSchema>>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
    resolver: schemaResolver(magicLinkSchema),
  });

  const onSubmit: SubmitHandler<z.infer<typeof magicLinkSchema>> = async (data_form) => {
    await signInAction('credentials', {
      email: data_form.email,
      redirectTo: props.redirectTo ?? '/',
    }).then(() => {
      session.update();
    });
  };

  return (
    <Box sx={{ borderColor: 'darkorchid', borderStyle: 'solid', borderRadius: 2, mt: 2, p: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormError errors={formState.errors} />

        <TextFieldElement control={control} autoComplete="email" label={t('common.email')} fullWidth name="email" required />

        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Button
            fullWidth
            loading={formState.isSubmitting}
            color="primary"
            type="submit"
            disableElevation
            variant="contained"
            sx={{ mt: 2 }}>
            {t('auth.login_with_credentials')}
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default Credentials;
